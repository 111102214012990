.svg-sprite-icon{
  path, g{
    fill: black !important;
  }
}

.dark {
  .svg-sprite-icon{
    path, g{
      fill: white !important;
    }
  }
}
