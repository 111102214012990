.box{
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
}

.black-link{
  font-size: 12px;
  font-weight: 600;
}

i.middle {
  position: relative;
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.bottom-save-bar {
  position: sticky;
  bottom: 0;
  background-color: $white;
  z-index: 9;
}

.overflow-x-auto {
  overflow-x: auto;
}

.divider {
  border-bottom: 1px solid $border-color;
}
.dark{
  .box{
    background-color: $dark-primary-bg;
  }
  .bottom-save-bar {
    background-color: $dark-primary-bg;
  }
  .divider {
    border-bottom: 1px solid $dark-border-color;
  }
}
.form-check-input:focus {
  box-shadow: none;
}

.slick-slider{
  &.white-bullet{
    margin-bottom: 50px;
    .slick-dots{
      bottom: -50px;
      li{
        width: 14px;
        button{
          background-color: rgba($white, .5);
          border-radius: 50%;
          width: 12px;
          height: 12px;
          margin: 0;
          &:before{
            display: none;
          }
        }
        &.slick-active{
          button{
            background-color: $white;
          }
        }
      }
    }
  }
}
