$black: #040d27;
$white: #ffffff;
$primary-color: #db683d;
$secondary-color: #273879;
$golden: #c69833;
$sky: #73b5da;
$green: #5a9b7b;
$yellow:  #ffc107;
$primary-green: #419d78;
$body: #f9f9f9;
$gray: #ccc;
$side-bar-width: 230px;
$light-gray: #f8f8f8;
$dim-white: #dbdbdf;
$light-white:#ffffff70;
$border-color : #dee2e6;
$placeholder-image-bg : #e9ecef;
$dark-primary-bg: #181f36;
$dark-secondary-bg: #020d29;
$dark-auth-bg: #06044D;

$dark-bg-grey-100:#151824;
$dark-bg-grey-200:#4C4C4C;
$dusk-blue: #23387d;
$dark-border-color : $dusk-blue;

$primary-font-family: 'Cairo', sans-serif;
$secondary-font-family: 'Open Sans', sans-serif;

$font-size-list : 10, 11, 12, 14, 16, 18, 20, 22, 24, 30;
$font-weight-list : 400, 600, 700, 800;
