@charset "UTF-8";

@font-face {
  font-family: "kaust";
  src:url("fonts/kaust.eot");
  src:url("fonts/kaust.eot?#iefix") format("embedded-opentype"),
    url("fonts/kaust.woff") format("woff"),
    url("fonts/kaust.ttf") format("truetype"),
    url("fonts/kaust.svg#kaust") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "kaust" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "kaust" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\61";
}
.icon-user:before {
  content: "\64";
}
.icon-light:before {
  content: "\65";
}
.icon-setting:before {
  content: "\66";
}
.icon-configuration:before {
  content: "\67";
}
.icon-data:before {
  content: "\68";
}
.icon-database:before {
  content: "\69";
}
.icon-home:before {
  content: "\6a";
}
.icon-invoice:before {
  content: "\6b";
}
.icon-list:before {
  content: "\6c";
}
.icon-moon:before {
  content: "\6d";
}
.icon-angle-down:before {
  content: "\6e";
}
.icon-angle-left:before {
  content: "\6f";
}
.icon-angle-right:before {
  content: "\70";
}
.icon-angle-up:before {
  content: "\71";
}
.icon-external-link:before {
  content: "\73";
}
.icon-team:before {
  content: "\74";
}
.icon-plus:before {
  content: "\75";
}
.icon-minus:before {
  content: "\76";
}
.icon-bell:before {
  content: "\62";
}
.icon-search:before {
  content: "\63";
}
.icon-eye-off:before {
  content: "\79";
}
.icon-trash:before {
  content: "\77";
}
.icon-right-arrow-line:before {
  content: "\78";
}
.icon-right-arrow:before {
  content: "\41";
}
.icon-left-arrow:before {
  content: "\42";
}
.icon-left-arrow-line:before {
  content: "\43";
}
.icon-cancel:before {
  content: "\46";
}
.icon-sort-asc:before {
  content: "\49";
}
.icon-sort-desc:before {
  content: "\4a";
}
.icon-organization:before {
  content: "\72";
}
.icon-check:before {
  content: "\45";
}
.icon-cloud-upload:before {
  content: "\44";
}
.icon-linkedin:before {
  content: "\4b";
}
.icon-info:before {
  content: "\4c";
}
.icon-check-list:before {
  content: "\4d";
}
.icon-tag:before {
  content: "\4e";
}
.icon-lock:before {
  content: "\4f";
}
.icon-three-dots:before {
  content: "\50";
}
.icon-square:before {
  content: "\51";
}
.icon-pause:before {
  content: "\52";
}
.icon-play:before {
  content: "\53";
}
.icon-cross:before {
  content: "\54";
}
.icon-locked:before {
  content: "\55";
}
.icon-unlocked:before {
  content: "\56";
}
.icon-logs:before {
  content: "\57";
}
.icon-detect:before {
  content: "\58";
}
.icon-train:before {
  content: "\59";
}
.icon-info-circle:before {
  content: "\5a";
}
.icon-share:before {
  content: "\7a";
}
.icon-google:before {
  content: "\30";
}
.icon-eye:before {
  content: "\31";
}
.icon-envelope:before {
  content: "\33";
}
.icon-logout:before {
  content: "\32";
}
.icon-bookmark:before {
  content: "\34";
}
.icon-tag-ting:before {
  content: "\35";
}
.icon-quote:before {
  content: "\36";
}
.icon-rss-square:before {
  content: "\37";
}
.icon-arrow-line:before {
  content: "\39";
}
.icon-github:before {
  content: "\21";
}
.icon-www:before {
  content: "\22";
}
.icon-blogs:before {
  content: "\23";
}
.icon-house:before {
  content: "\24";
}
.icon-image:before {
  content: "\25";
}
.icon-chat:before {
  content: "\26";
}
.icon-category:before {
  content: "\27";
}
.icon-behance:before {
  content: "\38";
}
.icon-discord:before {
  content: "\29";
}
.icon-dropbox:before {
  content: "\28";
}
.icon-quotes:before {
  content: "\2a";
}
.icon-image-2:before {
  content: "\2d";
}
.icon-plus-circle:before {
  content: "\2e";
}
.icon-pencil:before {
  content: "\3a";
}
.icon-grid:before {
  content: "\3b";
}
.icon-small-grid:before {
  content: "\3c";
}
.icon-book-open:before {
  content: "\3d";
}
.icon-facebook:before {
  content: "\2b";
}
.icon-twitter:before {
  content: "\2c";
}
.icon-image-placeholder:before {
  content: "\2f";
}
.icon-save:before {
  content: "\3e";
}
.icon-checked-circle:before {
  content: "\3f";
}
.icon-close-circle:before {
  content: "\40";
}
.icon-hand:before {
  content: "\5b";
}
.icon-draw:before {
  content: "\5e";
}
.icon-file:before {
  content: "\7b";
}
.icon-zoom-in:before {
  content: "\60";
}
.icon-text:before {
  content: "\47";
}
.icon-circle:before {
  content: "\7c";
}
.icon-search-minus:before {
  content: "\5f";
}
.icon-search-plus:before {
  content: "\48";
}
.icon-clone:before {
  content: "\7e";
}
.icon-page-export:before {
  content: "\5c";
}
.icon-file-export:before {
  content: "\5d";
}
.icon-founders:before {
  content: "\7d";
}
.icon-employees:before {
  content: "\e000";
}
.icon-instagram:before {
  content: "\e001";
}
.icon-intro:before {
  content: "\e002";
}
.icon-bookmark-fill:before {
  content: "\e003";
}
.icon-arrow-down:before {
  content: "\e004";
}
.icon-arrow-up:before {
  content: "\e005";
}
.icon-default-rates:before {
  content: "\e006";
}
.icon-pre-paid:before {
  content: "\e007";
}
.icon-rates:before {
  content: "\e008";
}
.icon-view-point:before {
  content: "\e009";
}
.icon-grid-view:before {
  content: "\e00a";
}
.icon-list-view:before {
  content: "\e00b";
}
.icon-product:before {
  content: "\e00c";
}
.icon-youtube:before {
  content: "\e00d";
}
.icon-rectangle:before {
  content: "\e00f";
}
.icon-edit-2:before {
  content: "\e00e";
}
.icon-link:before{
  content: "\1F517";
}
